////////////////////////////---//
// BEGIN CUSTOM JS SCRIPTS
////////////////////////////---//




$(document).ready(function () {
    $('.feat-more').hover(function(){
        $('.drp').toggleClass('dim');
        $('.feat-title').toggleClass('bright');
    });
});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

$('#fblogo').hover(function () {
    $(this).addClass('active');
    $('.borboletas').css('display','block');
    // $('.fb-info').css('display','block');
});


